.table-devices {
    float: none;
    margin: 0 auto;
}

.scenario-home {
    min-height: 100%;
    max-height: 100%;
}

.scenario-list {
    text-align: center;
    max-height: 100%;
    margin-bottom: 80px;
}

.scenario-dragged {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.fab-fixed {
    position: fixed !important;
    bottom: 30px;
    right: 30px;
    z-index: 10;
}

.new-fab-btn {
    width: 50px;
    height: 50px;
    padding: 0px;
    border-radius: 50%;
    font-size: 24pt;
    color: white;
    border: none;
}

.scenario-card {
    user-select: none;
    max-width: 320px;
    max-height: 140px;
    min-width: 320px;
    min-height: 140px;
    margin: 15px;
    border-radius: 5px;
    display: inline-flex;
    flex-direction: row;
    text-align: center;
    justify-content: center; //TODO: fix this for the scenario card alignments
    font-size: 18pt;
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.1);
    vertical-align: top;
}

.scenario-card-info {
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
    flex-direction: column;
    padding: 10px;
}

.scenario-card-icons {
    background-color: rgba(0, 0, 0, 0.04);
    width: 50px;
    font-size: 16pt;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-left: 1px solid rgba(0, 0, 0, 0.03);
    // justify-content: center;
}

.scenario-description {
    font-size: 22pt;
    font-weight: lighter;
    margin: 0 50px 20px 20px;
}

.scenario-card-favourite {
    font-size: 16pt;
    position: absolute;
    top: 10px;
    left: 10px;
}

.settings-title {
    font-size: 56pt;
    font-weight: 300;
    margin-left: 100px;
    margin-right: 120px;

    @media (max-width: $screen-sm) {
        font-size: 36pt;
    }
}

.execute-scenario {
    background-color: transparent;
    border: none;
    position: absolute;
    top: 30px;
    right: 4vw;

    @media (max-width: $screen-sm) {
        position: static;
        float: left;
        // margin-left: 20px;
    }
}

.scenario-show-buttons {
    float: right;
    margin-right: 5.5%;
}

.execute-scenario-button {
    font-size: 46pt;

    @media (max-width: $screen-sm) {
        font-size: 32pt;
    }
}

.favourite-icon {
    font-size: 80%;
    margin-right: 30px;
}

.device-scenario-container {
    flex-grow: 1;
    flex-shrink: 1;
    /* 2 */
    overflow: auto;
    border-radius: 5px;
}

.scenario-container {
    // max-width: 1200px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: auto;
    min-height: 100%;

    @media (min-width: $screen-sm-plus-one) {
        max-height: 100vh;
    }
}

.scenario-header {
    flex-shrink: 0;
    // margin: 20px;
    padding: 25px;
    border-radius: 5px;
}

.scenario-footer {
    flex-shrink: 0;
    margin: 20px 0;
    text-align: right;
    padding-bottom: 100px;
}

.device-scenario-item {
    padding: 15px 20px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
}

.flex-shrink-0 {
    flex-shrink: 0;
}

.light-border-bottom {
    border-bottom: 2px solid $light-primary;
}

.dark-border-bottom {
    border-bottom: 1px solid $dark-primary;
}

.device-scenario-type {
    width: 20px;
    display: inline-block;
    text-align: center;
    margin-right: 20px;
}

.scenario-device-path {
    opacity: 0.5;

    @media (max-width: $screen-md) {
        display: none;
    }
}

.widget-device-path {
    opacity: 0.5;
}

.widget .modal-content div {
    margin-top: 0 !important;
}

.remove-device-to-scenario-btn {
    margin-top: 40px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 5px;
    width: 100%;
    color: white;
    height: 40px;
    font-size: 14pt;
    font-weight: 300;
    outline: none;
    background-color: $accent-red;
}

.add-device-to-scenario-btn {
    margin-top: 40px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 5px;
    width: 100%;
    color: white;
    height: 40px;
    font-size: 14pt;
    font-weight: 300;
    outline: none;
    background-color: $accent-green;
}

.scenario-card-subtitle {
    font-size: 12pt;
    font-weight: 200;
}
