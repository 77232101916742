.events-list-view-item {
    background-color: var(--secondary) !important;
}

.events-list-view-item:hover {
    background-color: rgba(0, 0, 0, 0.08) !important;
}

.calendar-container {
    flex-grow: 1;
    margin: 0 70px 40px 70px;
    box-sizing: 'border-box';

    @media (max-width: $screen-sm) {
        margin: 10px;
    }
}

.repeating-day-list-view {
    @media (max-width: $screen-sm) {
        display: none;
    }
}

.fc-left {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.fc-left > * {
    margin: 5px !important;
}

.fc-event {
    min-height: 15px;
}
