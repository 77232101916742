.color-accent-green {
    color: $accent-green;
    fill: $accent-green;
}

.color-accent-red {
    color: $accent-red;
    fill: $accent-red;
}

.color-accent-blue {
    color: $accent-blue;
    fill: $accent-blue;
}

.color-accent {
    color: var(--accent);
}

.my-bg-primary {
    background-color: var(--primary) !important;
    color: var(--text-color);
}

.text-primary {
    color: var(--text-color) !important;
}

.my-bg-secondary {
    background-color: var(--secondary) !important;
    color: var(--text-color);
}

.my-bg-accent {
    background-color: var(--accent);
    color: white;
}

button:hover.my-bg-primary {
    background-color: var(--primary) !important;
    opacity: 0.7;
    color: var(--text-color);
}

button:hover.my-bg-secondary {
    background-color: var(--secondary) !important;
    opacity: 0.7;
    color: var(--text-color);
}

button:hover.my-bg-accent {
    background-color: var(--accent);
    opacity: 0.7;
    color: white;
}

.border-accent {
    border-color: var(--accent) !important;
}

.my-bg-accent-green {
    background-color: $accent-green;
}

.my-bg-accent-red {
    background-color: $accent-red;
}

.my-bg-accent-blue {
    background-color: $accent-blue;
}

.my-bg-accent-green:hover {
    background-color: darken($accent-green, 10%) !important;
}

.my-bg-accent-red:hover {
    background-color: darken($accent-red, 10%) !important;
}

.my-bg-accent-blue:hover {
    background-color: darken($accent-blue, 10%) !important;
}

// .border-accent-green {
//     border-color: $accent-green !important;
// }
// .border-accent-red {
//     border-color: $accent-red !important;
// }
// .border-accent-blue {
//     border-color: $accent-blue !important;
// }

.color-white {
    color: white;
}

.color-black {
    color: black;
}

.color-white:hover {
    color: white;
}

.color-black:hover {
    color: black;
}
