$screen-xs: 480px !default;
$screen-sm: 768px !default;
$screen-sm-plus-one: 769px !default;
$screen-md: 992px !default;
$screen-lg: 1200px !default;

$accent-green: #3c9867;
$accent-red: #e23e3e;
$accent-blue: #257ac9;

$brand-primary: darken(#428bca, 6.5%) !default;
$brand-success: #5cb85c !default;
$brand-info: #5bc0de !default;
$brand-warning: #f0ad4e !default;
$brand-danger: #d9534f !default;

$light-primary: #fff;
$light-secondary: #f4f5f7;

$dark-primary: #253134;
$dark-secondary: #303d45;

:export {
    green: $accent-green;
    blue: $accent-blue;
    red: $accent-red;
    light-primary: $light-primary;
    light-secondary: $light-secondary;
    dark-primary: $dark-primary;
    dark-secondary: $dark-secondary;
}

$pretty--color-primary: $accent-blue;
$pretty--color-success: $accent-green;
$pretty--color-danger: $accent-red;

:root {
    --primary: #fff;
    --secondary: #f4f5f7;
    --accent: #3c9867;
}