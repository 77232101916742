.loading-indicator {
  margin-top: 100px;
  display: flex;
  justify-content: center;
}

.home-card {
  display: inline-flex !important;
  flex-direction: column;
  width: 14rem;
  height: 10rem;
  vertical-align: top;
  text-align: center;
  margin: 15px;
}

.home-list {
  text-align: center;
  padding-bottom: 140px;
}

.thermo-widget-body {
  display: flex;
}

.thermo-widget-temperature {
  flex-grow: 1;
  font-size: 34pt;
  line-height: 0.8;
}

.thermo-widget-setpoint {
  font-size: 14pt;
}

.thermo-widget-info {
  flex-grow: 1;
  text-align: left;
  margin-left: 15px;
}

.float-right {
  float: right;
}

.text-align-left {
  text-align: left;
}

.swal2-title,
.swal2-content {
  color: inherit !important;
}

.widget-menu {
  text-align: right;
  margin-right: 15px;
  background-color: var(--secondary);
  // box-shadow: none;
  // padding: 10px;
  border: none;
}

.widget-menu-item {
  font-size: 14pt;
  padding: 10px 20px;
  color: var(--text-color);
  opacity: 0.8;
}

.widget-menu-item:hover {
  background: var(--primary);
  color: var(--text-color);
  opacity: 1;
}

.widget-menu-item-icon {
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
  margin-left: 15px;
}

.widget-option-button,
.widget-option-button:hover,
.widget-option-button:active {
  background: transparent !important;
  border: none !important;
  color: var(--text-color) !important;
  padding: 0 !important;
  margin: 0 !important;
}

.widget-card-footer {
  text-align: right;
  padding: 0 20px 10px 0;
  position: absolute;
  bottom: 0;
  right: 0;
}

.widget-option {
  background-color: var(--secondary);
}

.widget-option-item {
  color: var(--text-color);
  opacity: 0.8;
}

.widget-option-item:hover {
  background: var(--primary);
  color: var(--text-color);
  opacity: 1;
}

.light-widget-container {
  display: flex;
  justify-items: center;
  justify-content: space-around;
  align-items: center;
  height: 100%;
}

.light-widget-icon {
  flex: 1;
}

.light-widget-status {
  flex: 2;
  justify-content: space-around;
  padding-left: 15px;
  padding-right: 15px;
  height: 100%;
  display: flex;
  flex-direction: column;
}
