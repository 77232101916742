/* barlow-condensed-100 - latin */
@font-face {
    font-family: 'Barlow Condensed';
    font-style: normal;
    font-weight: 100;
    src: local('Barlow Condensed Thin'), local('BarlowCondensed-Thin'),
        url('./barlow-condensed-v1-latin-100.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./barlow-condensed-v1-latin-100.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* barlow-condensed-100italic - latin */
@font-face {
    font-family: 'Barlow Condensed';
    font-style: italic;
    font-weight: 100;
    src: local('Barlow Condensed Thin Italic'),
        local('BarlowCondensed-ThinItalic'),
        url('./barlow-condensed-v1-latin-100italic.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./barlow-condensed-v1-latin-100italic.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* barlow-condensed-200 - latin */
@font-face {
    font-family: 'Barlow Condensed';
    font-style: normal;
    font-weight: 200;
    src: local('Barlow Condensed ExtraLight'),
        local('BarlowCondensed-ExtraLight'),
        url('./barlow-condensed-v1-latin-200.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./barlow-condensed-v1-latin-200.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* barlow-condensed-200italic - latin */
@font-face {
    font-family: 'Barlow Condensed';
    font-style: italic;
    font-weight: 200;
    src: local('Barlow Condensed ExtraLight Italic'),
        local('BarlowCondensed-ExtraLightItalic'),
        url('./barlow-condensed-v1-latin-200italic.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./barlow-condensed-v1-latin-200italic.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* barlow-condensed-300 - latin */
@font-face {
    font-family: 'Barlow Condensed';
    font-style: normal;
    font-weight: 300;
    src: local('Barlow Condensed Light'), local('BarlowCondensed-Light'),
        url('./barlow-condensed-v1-latin-300.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./barlow-condensed-v1-latin-300.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* barlow-condensed-300italic - latin */
@font-face {
    font-family: 'Barlow Condensed';
    font-style: italic;
    font-weight: 300;
    src: local('Barlow Condensed Light Italic'),
        local('BarlowCondensed-LightItalic'),
        url('./barlow-condensed-v1-latin-300italic.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./barlow-condensed-v1-latin-300italic.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* barlow-condensed-regular - latin */
@font-face {
    font-family: 'Barlow Condensed';
    font-style: normal;
    font-weight: 400;
    src: local('Barlow Condensed Regular'), local('BarlowCondensed-Regular'),
        url('./barlow-condensed-v1-latin-regular.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./barlow-condensed-v1-latin-regular.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* barlow-condensed-italic - latin */
@font-face {
    font-family: 'Barlow Condensed';
    font-style: italic;
    font-weight: 400;
    src: local('Barlow Condensed Italic'), local('BarlowCondensed-Italic'),
        url('./barlow-condensed-v1-latin-italic.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./barlow-condensed-v1-latin-italic.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* barlow-condensed-500 - latin */
@font-face {
    font-family: 'Barlow Condensed';
    font-style: normal;
    font-weight: 500;
    src: local('Barlow Condensed Medium'), local('BarlowCondensed-Medium'),
        url('./barlow-condensed-v1-latin-500.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./barlow-condensed-v1-latin-500.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* barlow-condensed-500italic - latin */
@font-face {
    font-family: 'Barlow Condensed';
    font-style: italic;
    font-weight: 500;
    src: local('Barlow Condensed Medium Italic'),
        local('BarlowCondensed-MediumItalic'),
        url('./barlow-condensed-v1-latin-500italic.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./barlow-condensed-v1-latin-500italic.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* barlow-condensed-600 - latin */
@font-face {
    font-family: 'Barlow Condensed';
    font-style: normal;
    font-weight: 600;
    src: local('Barlow Condensed SemiBold'), local('BarlowCondensed-SemiBold'),
        url('./barlow-condensed-v1-latin-600.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./barlow-condensed-v1-latin-600.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* barlow-condensed-600italic - latin */
@font-face {
    font-family: 'Barlow Condensed';
    font-style: italic;
    font-weight: 600;
    src: local('Barlow Condensed SemiBold Italic'),
        local('BarlowCondensed-SemiBoldItalic'),
        url('./barlow-condensed-v1-latin-600italic.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./barlow-condensed-v1-latin-600italic.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* barlow-condensed-700 - latin */
@font-face {
    font-family: 'Barlow Condensed';
    font-style: normal;
    font-weight: 700;
    src: local('Barlow Condensed Bold'), local('BarlowCondensed-Bold'),
        url('./barlow-condensed-v1-latin-700.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./barlow-condensed-v1-latin-700.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* barlow-condensed-700italic - latin */
@font-face {
    font-family: 'Barlow Condensed';
    font-style: italic;
    font-weight: 700;
    src: local('Barlow Condensed Bold Italic'),
        local('BarlowCondensed-BoldItalic'),
        url('./barlow-condensed-v1-latin-700italic.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./barlow-condensed-v1-latin-700italic.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* barlow-condensed-800 - latin */
@font-face {
    font-family: 'Barlow Condensed';
    font-style: normal;
    font-weight: 800;
    src: local('Barlow Condensed ExtraBold'), local('BarlowCondensed-ExtraBold'),
        url('./barlow-condensed-v1-latin-800.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./barlow-condensed-v1-latin-800.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* barlow-condensed-800italic - latin */
@font-face {
    font-family: 'Barlow Condensed';
    font-style: italic;
    font-weight: 800;
    src: local('Barlow Condensed ExtraBold Italic'),
        local('BarlowCondensed-ExtraBoldItalic'),
        url('./barlow-condensed-v1-latin-800italic.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./barlow-condensed-v1-latin-800italic.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* barlow-condensed-900italic - latin */
@font-face {
    font-family: 'Barlow Condensed';
    font-style: italic;
    font-weight: 900;
    src: local('Barlow Condensed Black Italic'),
        local('BarlowCondensed-BlackItalic'),
        url('./barlow-condensed-v1-latin-900italic.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./barlow-condensed-v1-latin-900italic.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* barlow-condensed-900 - latin */
@font-face {
    font-family: 'Barlow Condensed';
    font-style: normal;
    font-weight: 900;
    src: local('Barlow Condensed Black'), local('BarlowCondensed-Black'),
        url('./barlow-condensed-v1-latin-900.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./barlow-condensed-v1-latin-900.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}