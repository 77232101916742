.action-button {
  border-radius: 50%;
  width: 3.5em;
  height: 3.5em;
  margin: 0.5em;
  border: none;
  outline: none;
}

.edit-map {
  color: white;
}

.save-map {
  background-color: $brand-success;
  color: white;
}

.cancel-map {
  background-color: $brand-danger;
  color: white;
}

.edit-button-div {
  position: absolute;
  top: 25px;
  right: 20px;

  @media (max-width: $screen-sm) {
    position: static;
    text-align: right;
  }
}

#map-div {
  display: flex;
  justify-content: center;
  margin: 0 20px;
}

.react-grid-layout {
  height: 100% !important;
}

.map-image {
  // box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 5px;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
