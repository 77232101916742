ul.omnivision-menu{
    margin : 20%;
    text-align:center;
}
.omnivision > h1, .omnivision > h2, .omnivision > h3, .omnivision > h4, .omnivision > h5 {
    color: var(--accent);
}
.omnivision-menu a, .omnivision-menu a:visited, .omnivision-menu a:hover {
    color: var(--accent);
    text-decoration: none;
}

.omnivision {
    margin :5%;
}

.omnivision-text-right {
    text-align:right;
}
.omnivision-text-center {
    text-align: center;
}

.omnivision-menu, omnivision-menu > li, ul.omnivision-menu {
    list-style: none;
}
