.aside {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 116;
    background-color: white;
    width: 220px;
}

.aside-inner {
    padding-top: 55px;
}

.WidgetHome {
    margin: 20px 0;
}

.badge {
    background-color: #f0ad4e;
    margin-left: 20px;
}
