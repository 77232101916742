/* fira-sans-extra-condensed-100 - latin */
@font-face {
    font-family: 'Fira Sans Extra Condensed';
    font-style: normal;
    font-weight: 100;
    src: local('Fira Sans Extra Condensed Thin'),
        local('FiraSansExtraCondensed-Thin'),
        url('./fira-sans-extra-condensed-v2-latin-100.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./fira-sans-extra-condensed-v2-latin-100.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* fira-sans-extra-condensed-100italic - latin */
@font-face {
    font-family: 'Fira Sans Extra Condensed';
    font-style: italic;
    font-weight: 100;
    src: local('Fira Sans Extra Condensed Thin Italic'),
        local('FiraSansExtraCondensed-ThinItalic'),
        url('./fira-sans-extra-condensed-v2-latin-100italic.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./fira-sans-extra-condensed-v2-latin-100italic.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* fira-sans-extra-condensed-200 - latin */
@font-face {
    font-family: 'Fira Sans Extra Condensed';
    font-style: normal;
    font-weight: 200;
    src: local('Fira Sans Extra Condensed ExtraLight'),
        local('FiraSansExtraCondensed-ExtraLight'),
        url('./fira-sans-extra-condensed-v2-latin-200.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./fira-sans-extra-condensed-v2-latin-200.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* fira-sans-extra-condensed-200italic - latin */
@font-face {
    font-family: 'Fira Sans Extra Condensed';
    font-style: italic;
    font-weight: 200;
    src: local('Fira Sans Extra Condensed ExtraLight Italic'),
        local('FiraSansExtraCondensed-ExtraLightItalic'),
        url('./fira-sans-extra-condensed-v2-latin-200italic.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./fira-sans-extra-condensed-v2-latin-200italic.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* fira-sans-extra-condensed-300 - latin */
@font-face {
    font-family: 'Fira Sans Extra Condensed';
    font-style: normal;
    font-weight: 300;
    src: local('Fira Sans Extra Condensed Light'),
        local('FiraSansExtraCondensed-Light'),
        url('./fira-sans-extra-condensed-v2-latin-300.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./fira-sans-extra-condensed-v2-latin-300.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* fira-sans-extra-condensed-300italic - latin */
@font-face {
    font-family: 'Fira Sans Extra Condensed';
    font-style: italic;
    font-weight: 300;
    src: local('Fira Sans Extra Condensed Light Italic'),
        local('FiraSansExtraCondensed-LightItalic'),
        url('./fira-sans-extra-condensed-v2-latin-300italic.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./fira-sans-extra-condensed-v2-latin-300italic.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* fira-sans-extra-condensed-regular - latin */
@font-face {
    font-family: 'Fira Sans Extra Condensed';
    font-style: normal;
    font-weight: 400;
    src: local('Fira Sans Extra Condensed Regular'),
        local('FiraSansExtraCondensed-Regular'),
        url('./fira-sans-extra-condensed-v2-latin-regular.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./fira-sans-extra-condensed-v2-latin-regular.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* fira-sans-extra-condensed-italic - latin */
@font-face {
    font-family: 'Fira Sans Extra Condensed';
    font-style: italic;
    font-weight: 400;
    src: local('Fira Sans Extra Condensed Italic'),
        local('FiraSansExtraCondensed-Italic'),
        url('./fira-sans-extra-condensed-v2-latin-italic.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./fira-sans-extra-condensed-v2-latin-italic.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* fira-sans-extra-condensed-500 - latin */
@font-face {
    font-family: 'Fira Sans Extra Condensed';
    font-style: normal;
    font-weight: 500;
    src: local('Fira Sans Extra Condensed Medium'),
        local('FiraSansExtraCondensed-Medium'),
        url('./fira-sans-extra-condensed-v2-latin-500.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./fira-sans-extra-condensed-v2-latin-500.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* fira-sans-extra-condensed-500italic - latin */
@font-face {
    font-family: 'Fira Sans Extra Condensed';
    font-style: italic;
    font-weight: 500;
    src: local('Fira Sans Extra Condensed Medium Italic'),
        local('FiraSansExtraCondensed-MediumItalic'),
        url('./fira-sans-extra-condensed-v2-latin-500italic.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./fira-sans-extra-condensed-v2-latin-500italic.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* fira-sans-extra-condensed-600 - latin */
@font-face {
    font-family: 'Fira Sans Extra Condensed';
    font-style: normal;
    font-weight: 600;
    src: local('Fira Sans Extra Condensed SemiBold'),
        local('FiraSansExtraCondensed-SemiBold'),
        url('./fira-sans-extra-condensed-v2-latin-600.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./fira-sans-extra-condensed-v2-latin-600.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* fira-sans-extra-condensed-600italic - latin */
@font-face {
    font-family: 'Fira Sans Extra Condensed';
    font-style: italic;
    font-weight: 600;
    src: local('Fira Sans Extra Condensed SemiBold Italic'),
        local('FiraSansExtraCondensed-SemiBoldItalic'),
        url('./fira-sans-extra-condensed-v2-latin-600italic.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./fira-sans-extra-condensed-v2-latin-600italic.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* fira-sans-extra-condensed-700 - latin */
@font-face {
    font-family: 'Fira Sans Extra Condensed';
    font-style: normal;
    font-weight: 700;
    src: local('Fira Sans Extra Condensed Bold'),
        local('FiraSansExtraCondensed-Bold'),
        url('./fira-sans-extra-condensed-v2-latin-700.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./fira-sans-extra-condensed-v2-latin-700.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* fira-sans-extra-condensed-700italic - latin */
@font-face {
    font-family: 'Fira Sans Extra Condensed';
    font-style: italic;
    font-weight: 700;
    src: local('Fira Sans Extra Condensed Bold Italic'),
        local('FiraSansExtraCondensed-BoldItalic'),
        url('./fira-sans-extra-condensed-v2-latin-700italic.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./fira-sans-extra-condensed-v2-latin-700italic.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* fira-sans-extra-condensed-800 - latin */
@font-face {
    font-family: 'Fira Sans Extra Condensed';
    font-style: normal;
    font-weight: 800;
    src: local('Fira Sans Extra Condensed ExtraBold'),
        local('FiraSansExtraCondensed-ExtraBold'),
        url('./fira-sans-extra-condensed-v2-latin-800.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./fira-sans-extra-condensed-v2-latin-800.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* fira-sans-extra-condensed-800italic - latin */
@font-face {
    font-family: 'Fira Sans Extra Condensed';
    font-style: italic;
    font-weight: 800;
    src: local('Fira Sans Extra Condensed ExtraBold Italic'),
        local('FiraSansExtraCondensed-ExtraBoldItalic'),
        url('./fira-sans-extra-condensed-v2-latin-800italic.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./fira-sans-extra-condensed-v2-latin-800italic.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* fira-sans-extra-condensed-900 - latin */
@font-face {
    font-family: 'Fira Sans Extra Condensed';
    font-style: normal;
    font-weight: 900;
    src: local('Fira Sans Extra Condensed Black'),
        local('FiraSansExtraCondensed-Black'),
        url('./fira-sans-extra-condensed-v2-latin-900.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./fira-sans-extra-condensed-v2-latin-900.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* fira-sans-extra-condensed-900italic - latin */
@font-face {
    font-family: 'Fira Sans Extra Condensed';
    font-style: italic;
    font-weight: 900;
    src: local('Fira Sans Extra Condensed Black Italic'),
        local('FiraSansExtraCondensed-BlackItalic'),
        url('./fira-sans-extra-condensed-v2-latin-900italic.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./fira-sans-extra-condensed-v2-latin-900italic.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}