.sidebar-menu-title {
    // background-color: $nav-top-bg;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
    height: 55px;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    cursor: default;
}

.sidebar-elements {
    padding: 16px;
    margin-top: 60px;

    &__element {
        cursor: pointer;
    }
}

.sidebar-title {
    color: white;
    margin-top: 17px;
    margin-bottom: 17px;
    padding-left: 25px;
}

.aside-inner {
    overflow-y: scroll;
    max-height: 1340px;
    height: 100%;
}

.sidebar-icon-full {
    width: 40px;
    text-align: center;
}

.sidebar-text {
    margin-left: 15px;
}
