.panel-settings {
    margin: 30px;
    margin-left: 70px;
    margin-right: 70px;
    height: 100px;
    display: flex;
    align-items: center;
    font-size: 24pt;
    font-weight: 300;
    border-radius: 5px;
    cursor: pointer;

    @media (max-width: $screen-sm) {
        margin: 15px 20px;
        height: auto;
        padding: 15px 0;
    }
}

.panel-settings-icon {
    margin-left: 40px;
    margin-right: 40px;
    font-size: 20pt;
}

.settings-title {
    font-size: 56pt;
    font-weight: 300;
    margin-left: 100px;

    @media (max-width: $screen-sm) {
        font-size: 36pt;
        margin-right: 100px;
    }
}

#submit-button-preferences {
    margin-left: 15px;

    @media (max-width: $screen-md) {
        margin: 20px 25% 0;
    }
}

.password-button {
    @media (max-width: $screen-md) {
        margin: 20px 25% 0;
    }
}

.settings-option {
    border-radius: 5px;
    margin: 15px 70px;
    font-size: 14pt;
    font-weight: 200 !important;
    padding: 30px;

    @media (max-width: $screen-sm) {
        height: auto;
        margin: 15px 30px;
    }
}

.settings-option-title {
    font-size: 26pt;
    margin-bottom: 20px;
}

.settings-option-label {
    margin-left: 10px;
    font-weight: 200 !important;
}
