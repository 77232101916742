td.fc-day-today {
  background-color: var(--secondary) !important;
  color: var(--text-color);
}

.fc-theme-standard th {
  background-color: var(--secondary) !important;
  // border-width: 2px;
}

hr.fc-divider.fc-widget-header {
  background-color: var(--secondary) !important;
  border-color: var(--secondary);
  border-width: 2px;
}

.fc-button-primary {
  background-color: var(--secondary) !important;
  border: none !important;
  color: var(--text-color) !important;
}

.fc-button-primary:hover {
  background-color: var(--secondary) !important;
  opacity: 0.5 !important;
  border: none !important;
  color: var(--text-color) !important;
}

.fc-button-primary:disabled {
  background-color: var(--accent) !important;
  opacity: 1 !important;
  border: none !important;
  color: white !important;
}

.fc-button-active {
  background-color: var(--accent) !important;
  color: white !important;
}

// td.fc-widget-header {
//   background-color: var(--secondary) !important;
// }

// div.fc-list-view {
//   border-color: var(--secondary) !important;
// }

// .fc-list-item:hover td {
//   background-color: var(--secondary) !important;
// }

// .fc-list-empty {
//   background-color: transparent !important;
// }
