.content-display {
    padding: 0 20px;

    &--devices,
    &--scenario,
    &--alarms,
    &--settings {
        margin-top: 20px;
        padding: 0 20px;
    }
}

.content-display {

    &--devices,
    &--scenario,
    &--alarms,
    &--settings {
        @media (max-width: $screen-md) {
            margin-left: 0;
            width: 100%;
        }
    }
}

.weather-container {
    margin-top: 20px;
}

.programs,
.lock {
    margin-left: 0;
}

.active {
    background-color: red;
}

.hamburger {
    display: none;

    @media (max-width: $screen-sm) {
        background: transparent;
        border: none;
        display: inline;
        z-index: 10;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        position: absolute;
        outline: none;
        top: 10px;
        left: 10px;
    }
}

.clean-screen {
    position: absolute;
    bottom: 0;
    top: 0;
    right: 0;
    left: 0;
    z-index: 100000;
    background-color: white;
}

.clean-screen-text {
    position: absolute;
    bottom: 0;
    top: 0;
    right: 0;
    left: 0;
}

.clean-screen-text__clean {
    font-size: 30px;
    position: relative;
    top: 40%;
    text-align: center;
}

#home-container {
    @media (min-width: $screen-md) {
        margin: 30px;
    }
}

.component {
    @media (max-width: $screen-md) {
        display: flex;
        flex-wrap: wrap;
    }
}

.aside {
    @media (max-width: $screen-md) {
        position: static;
        width: 100%;
        border-top: 0.5px solid #cfdbe2;
        border-bottom: 0.5px solid #cfdbe2;
    }
}

.aside-sidebar-menu {
    @media (max-width: $screen-md) {
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

.sidebar {
    z-index: 11 !important;
}

.sidebar-full {
    width: 230px;
}

.sidebar-compact {
    width: 75px;
}

.sidebar-item {
    font-weight: 200;
    font-size: 18pt;
    padding: 15px;
    cursor: pointer;
}

.sidebar-collapse {
    font-weight: 200;
    font-size: 18pt;
    padding: 15px;
    height: 100px;
    cursor: pointer;
}

.transition-all {
    transition: all 0.5s ease;
}

.content-display {
    @media (max-width: $screen-md) {
        width: 100%;
        padding: 20px;
        margin: 0;
    }
}

.collapse-icon {
    margin: 20px;
}

.rot-180 {
    transform: rotate(180deg);
}

.content-display--programs {
    margin-left: 220px;
}

.content-display--programs {
    @media (max-width: $screen-md) {
        width: 100%;
        padding: 20px;
        margin: 0;
    }
}

.navigation-back {
    position: absolute;
    background-color: transparent;
    border: none;
    outline: none;

    @media (max-width: $screen-sm) {
        top: 20px;
        right: 30px;
        left: auto;
    }

    top: 18px;
    left: 20px;
    right: auto;
}

.titlebar {
    min-height: 100px;
    padding: 10px 60px 10px 10px;
    // margin-bottom: 20px;
    // box-shadow: 0 -10px 10px 10px rgba(0, 0, 0, 0.2);
}

.titlebar-title {
    padding-left: 60px;
}

.titlebar-subtitle {
    padding-left: 60px;
}
