.levels-buttons {
    display: flex;

    &__home,
    &__back {
        padding: 12px 24px;
        letter-spacing: .025em;
        font-weight: normal;
        cursor: pointer;
        width: 50%;
        text-align: center;

        &:hover {
            color: $brand-info;
        }
    }
}
