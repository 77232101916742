/* fira-sans-condensed-100 - latin */
@font-face {
    font-family: 'Fira Sans Condensed';
    font-style: normal;
    font-weight: 100;
    src: local('Fira Sans Condensed Thin'), local('FiraSansCondensed-Thin'),
        url('./fira-sans-condensed-v2-latin-100.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./fira-sans-condensed-v2-latin-100.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* fira-sans-condensed-100italic - latin */
@font-face {
    font-family: 'Fira Sans Condensed';
    font-style: italic;
    font-weight: 100;
    src: local('Fira Sans Condensed Thin Italic'),
        local('FiraSansCondensed-ThinItalic'),
        url('./fira-sans-condensed-v2-latin-100italic.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./fira-sans-condensed-v2-latin-100italic.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* fira-sans-condensed-200 - latin */
@font-face {
    font-family: 'Fira Sans Condensed';
    font-style: normal;
    font-weight: 200;
    src: local('Fira Sans Condensed ExtraLight'),
        local('FiraSansCondensed-ExtraLight'),
        url('./fira-sans-condensed-v2-latin-200.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./fira-sans-condensed-v2-latin-200.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* fira-sans-condensed-200italic - latin */
@font-face {
    font-family: 'Fira Sans Condensed';
    font-style: italic;
    font-weight: 200;
    src: local('Fira Sans Condensed ExtraLight Italic'),
        local('FiraSansCondensed-ExtraLightItalic'),
        url('./fira-sans-condensed-v2-latin-200italic.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./fira-sans-condensed-v2-latin-200italic.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* fira-sans-condensed-300 - latin */
@font-face {
    font-family: 'Fira Sans Condensed';
    font-style: normal;
    font-weight: 300;
    src: local('Fira Sans Condensed Light'), local('FiraSansCondensed-Light'),
        url('./fira-sans-condensed-v2-latin-300.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./fira-sans-condensed-v2-latin-300.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* fira-sans-condensed-300italic - latin */
@font-face {
    font-family: 'Fira Sans Condensed';
    font-style: italic;
    font-weight: 300;
    src: local('Fira Sans Condensed Light Italic'),
        local('FiraSansCondensed-LightItalic'),
        url('./fira-sans-condensed-v2-latin-300italic.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./fira-sans-condensed-v2-latin-300italic.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* fira-sans-condensed-regular - latin */
@font-face {
    font-family: 'Fira Sans Condensed';
    font-style: normal;
    font-weight: 400;
    src: local('Fira Sans Condensed Regular'),
        local('FiraSansCondensed-Regular'),
        url('./fira-sans-condensed-v2-latin-regular.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./fira-sans-condensed-v2-latin-regular.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* fira-sans-condensed-italic - latin */
@font-face {
    font-family: 'Fira Sans Condensed';
    font-style: italic;
    font-weight: 400;
    src: local('Fira Sans Condensed Italic'), local('FiraSansCondensed-Italic'),
        url('./fira-sans-condensed-v2-latin-italic.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./fira-sans-condensed-v2-latin-italic.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* fira-sans-condensed-500 - latin */
@font-face {
    font-family: 'Fira Sans Condensed';
    font-style: normal;
    font-weight: 500;
    src: local('Fira Sans Condensed Medium'), local('FiraSansCondensed-Medium'),
        url('./fira-sans-condensed-v2-latin-500.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./fira-sans-condensed-v2-latin-500.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* fira-sans-condensed-500italic - latin */
@font-face {
    font-family: 'Fira Sans Condensed';
    font-style: italic;
    font-weight: 500;
    src: local('Fira Sans Condensed Medium Italic'),
        local('FiraSansCondensed-MediumItalic'),
        url('./fira-sans-condensed-v2-latin-500italic.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./fira-sans-condensed-v2-latin-500italic.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* fira-sans-condensed-600 - latin */
@font-face {
    font-family: 'Fira Sans Condensed';
    font-style: normal;
    font-weight: 600;
    src: local('Fira Sans Condensed SemiBold'),
        local('FiraSansCondensed-SemiBold'),
        url('./fira-sans-condensed-v2-latin-600.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./fira-sans-condensed-v2-latin-600.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* fira-sans-condensed-600italic - latin */
@font-face {
    font-family: 'Fira Sans Condensed';
    font-style: italic;
    font-weight: 600;
    src: local('Fira Sans Condensed SemiBold Italic'),
        local('FiraSansCondensed-SemiBoldItalic'),
        url('./fira-sans-condensed-v2-latin-600italic.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./fira-sans-condensed-v2-latin-600italic.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* fira-sans-condensed-700 - latin */
@font-face {
    font-family: 'Fira Sans Condensed';
    font-style: normal;
    font-weight: 700;
    src: local('Fira Sans Condensed Bold'), local('FiraSansCondensed-Bold'),
        url('./fira-sans-condensed-v2-latin-700.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./fira-sans-condensed-v2-latin-700.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* fira-sans-condensed-700italic - latin */
@font-face {
    font-family: 'Fira Sans Condensed';
    font-style: italic;
    font-weight: 700;
    src: local('Fira Sans Condensed Bold Italic'),
        local('FiraSansCondensed-BoldItalic'),
        url('./fira-sans-condensed-v2-latin-700italic.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./fira-sans-condensed-v2-latin-700italic.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* fira-sans-condensed-800 - latin */
@font-face {
    font-family: 'Fira Sans Condensed';
    font-style: normal;
    font-weight: 800;
    src: local('Fira Sans Condensed ExtraBold'),
        local('FiraSansCondensed-ExtraBold'),
        url('./fira-sans-condensed-v2-latin-800.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./fira-sans-condensed-v2-latin-800.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* fira-sans-condensed-800italic - latin */
@font-face {
    font-family: 'Fira Sans Condensed';
    font-style: italic;
    font-weight: 800;
    src: local('Fira Sans Condensed ExtraBold Italic'),
        local('FiraSansCondensed-ExtraBoldItalic'),
        url('./fira-sans-condensed-v2-latin-800italic.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./fira-sans-condensed-v2-latin-800italic.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* fira-sans-condensed-900 - latin */
@font-face {
    font-family: 'Fira Sans Condensed';
    font-style: normal;
    font-weight: 900;
    src: local('Fira Sans Condensed Black'), local('FiraSansCondensed-Black'),
        url('./fira-sans-condensed-v2-latin-900.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./fira-sans-condensed-v2-latin-900.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* fira-sans-condensed-900italic - latin */
@font-face {
    font-family: 'Fira Sans Condensed';
    font-style: italic;
    font-weight: 900;
    src: local('Fira Sans Condensed Black Italic'),
        local('FiraSansCondensed-BlackItalic'),
        url('./fira-sans-condensed-v2-latin-900italic.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./fira-sans-condensed-v2-latin-900italic.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}