.panel.panel-primary.devices {
  margin: 0px;
}

.closePop {
  width: 100%;
}

.navbar_no-sublevels {
  padding: 12px 24px;
  letter-spacing: 0.025em;
  font-weight: normal;
  cursor: default;
}

.panel-devices--modal {
  margin-bottom: 0;
}

.on-off-toggle-text {
  margin-left: 15px;
  position: absolute;
}

.toggle-light {
  margin-top: 10px;
}

#form-light {
  padding-bottom: 30px;
}

.light-label {
  text-align: center;
}

.thermostat--setpoint {
  display: flex;
}

.thermostat-setpoint__text {
  margin: 0 15px;
}

.dimmer {
  &-container {
    display: flex;
    margin-top: 10px;
  }

  &-button {
    &-left {
      margin-right: 10px;
    }

    &-right {
      margin-left: 10px;
    }
  }

  &-slider {
    margin: 10px 15px;
  }
}

.device-modal-icon-subtitle {
  font-size: 12pt;
  text-align: center;
}

.device-panel {
  margin: 0 10px;
}

.quality-message__text {
  margin: 10px 20px 20px;
  font-weight: bold;
}

.position-handle {
  position: initial;
  border-radius: 10px;
  cursor: pointer;
  margin: 10px;
  padding: 10px;

  @media (min-width: $screen-sm-plus-one) {
    position: absolute;
    display: flex;
    justify-content: center;
    -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.69);
    -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.69);
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.69);
    align-items: center;
    margin: 0;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
  }
}

.device-list-mode {
  position: initial;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 20px;
  margin-right: 20px;
}

.devices-panel-heading {
  display: flex;
  justify-content: space-between;
  font-size: 16px !important;
}

.panel-history {
  margin: 10px;
}

.modal-history {
  @media (min-width: $screen-md) {
    width: 80%;
  }
}

.history-body {
  margin: 15px;
}

.history-buttons {
  display: flex;
  justify-content: space-between;
}

.history-date-picker {
  -webkit-box-align: center;
  -webkit-box-pack: center;
  display: -webkit-box;
}

.nothing-to-show {
  text-align: center;
  font-size: 16px;
  margin: 20px;
}

.map-container {
  height: 80vh;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

#map-buttons-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  @media (max-width: $screen-sm) {
    position: static;
    display: block;
    height: 100%;
  }
}

.button-item {
  display: flex;
  align-items: center;

  @media (max-width: $screen-sm) {
    justify-content: space-between;
    border-radius: 10px;
  }
}

.button-item-text-mode {
  display: flex;
  align-items: center;
  //   justify-content: space-between;
  margin: 10px;
  padding: 10px;
  border-radius: 10px;
}

.button-item-no-label {
  display: flex;
  align-items: center;

  @media (max-width: $screen-sm) {
    &__icon {
      margin: 0 20px 0 10px;
    }
  }
}

.with-label {
  @media (min-width: $screen-sm-plus-one) {
    width: auto;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 25px;
    -moz-border-radius: 25px;
    -webkit-border-radius: 25px;
  }
}

.button-with-name {
  display: flex;

  @media (min-width: $screen-sm-plus-one) {
    margin-left: 10px;
    margin-right: 5px;
  }
}

.button-no-name {
  display: none;

  @media (max-width: $screen-sm) {
    display: flex;
  }
}

.hide-on-small {
  @media (max-width: $screen-sm) {
    display: none;
  }
}

.draggable,
.draggable * {
  -ms-touch-action: none;
  touch-action: none;
}

// ------------------ NEW STUFF ----------------------------

.in-scenario {
  border: 4px solid;
}

.device-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.breadcrumb-container {
  height: 180px;
  padding-top: 10px;
  margin-left: 100px;
  margin-right: 100px;

  @media (max-width: $screen-sm) {
    height: auto;
  }
}

.breadcrumb-sup {
  font-size: 6.3vw;
  font-weight: 300;

  @media (max-width: $screen-sm) {
    font-size: 26pt;
  }

  @media (min-width: $screen-lg) {
    font-size: 56pt;
  }
}

.breadcrumb-sub {
  font-size: 20pt;
  font-weight: 300;
  cursor: pointer;

  @media (max-width: $screen-sm) {
    font-size: 16pt;
  }

  @media (max-width: $screen-sm) {
    font-size: 12pt;
  }
}

.half-icon {
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text !important;
  background-clip: text;
}

.slick-slide {
  overflow-y: auto;
}

.slick-slide * {
  outline: none !important;
}

.nav-devices-slider .slick-slide:not(.slick-current) {
  opacity: 0.5;
}

.devices-slider {
  flex-grow: 1;
}

.slick-slide > div {
  height: 100%;
  overflow: hidden;
}
// .nav-devices-slider {
//   flex-shrink: 1;
//   flex-grow: 1;
// }

.slick-slider,
.slick-list,
.slick-track {
  // flex-grow: 1;
  height: 100%;
}

.devices-container {
  @media (min-width: $screen-md) {
    height: calc(100vh - 200px);
  }
}

.devices-back {
  position: absolute !important;
  top: 32px;
  z-index: 10;
  right: 32px;
}

$devices-card-size: 120px;

.devices-flexbox {
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(auto-fill, $devices-card-size);
  grid-gap: 1em;
  justify-content: space-around;
  padding: 1em 0;
}

.devices-flexbox::after {
  content: "";
  flex: auto;
}

.devices-card {
  width: $devices-card-size;
  height: $devices-card-size;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px;
  line-height: 0.3em;
  border-radius: 10px;
}

.map-slide {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  @media (max-width: $screen-sm) {
    justify-content: flex-start;
    padding: 0;
  }
}

.map-slide-text-mode {
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: flex-start;
  padding: 0 30px;
  padding-bottom: 80px;
  overflow-y: scroll;
}

.device-off {
  color: #606060;
  fill: #606060;
}

// .device-half-blue {
//     background: $accent-blue;
//     background: -moz-linear-gradient(
//         top,
//         $accent-blue 0%,
//         $accent-blue 50%,
//         #606060 50%,
//         #606060 100%
//     );
//     background: -webkit-linear-gradient(
//         top,
//         $accent-blue 0%,
//         $accent-blue 50%,
//         #606060 50%,
//         #606060 100%
//     );
//     background: linear-gradient(
//         to bottom,
//         $accent-blue 0%,
//         $accent-blue 50%,
//         #606060 50%,
//         #606060 100%
//     );
//     -webkit-background-clip: text;
//     -webkit-text-fill-color: transparent;
// }

// .device-half-red {
//     background: $accent-red;
//     background: -moz-linear-gradient(
//         top,
//         $accent-red 0%,
//         $accent-red 50%,
//         #606060 50%,
//         #606060 100%
//     );
//     background: -webkit-linear-gradient(
//         top,
//         $accent-red 0%,
//         $accent-red 50%,
//         #606060 50%,
//         #606060 100%
//     );
//     background: linear-gradient(
//         to bottom,
//         $accent-red 0%,
//         $accent-red 50%,
//         #606060 50%,
//         #606060 100%
//     );
//     -webkit-background-clip: text;
//     -webkit-text-fill-color: transparent;
// }

// .device-half-green {
//     background: $accent-green;
//     background: -moz-linear-gradient(
//         top,
//         $accent-green 0%,
//         $accent-green 50%,
//         #606060 50%,
//         #606060 100%
//     );
//     background: -webkit-linear-gradient(
//         top,
//         $accent-green 0%,
//         $accent-green 50%,
//         #606060 50%,
//         #606060 100%
//     );
//     background: linear-gradient(
//         to bottom,
//         $accent-green 0%,
//         $accent-green 50%,
//         #606060 50%,
//         #606060 100%
//     );
//     -webkit-background-clip: text;
//     -webkit-text-fill-color: transparent;
// }

.devices-item-icon {
  margin-left: 5px;
  margin-right: 5px;

  @media (max-width: $screen-sm) {
    margin-right: 20px;
  }
}

.device-button-label {
  position: absolute;
  bottom: -12px;
  box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.69);
  border-radius: 5px;
  padding: 0 5px;
  left: 5px;

  @media (max-width: $screen-sm) {
    position: static;
    box-shadow: none;
  }

  width: max-content;
}

.device-button-label-text-mode {
  position: static;
  border-radius: 5px;
  margin-left: 10px;
  padding: 0 5px;
}

.circular-double-button {
  z-index: 10;
  width: 100px;
  height: 100px;
  display: flex;
  flex-direction: column;
  font-size: 16pt;
  font-weight: 300;
}

.circular-double-button > button {
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.device-modal-container {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 3px;
  padding: 20px;
  padding-bottom: 40px;
}

.device-modal-locked {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: rgba(150, 150, 150, 0.3);
}

.device-lock-icon {
  position: absolute;
  bottom: 20px;
  right: 20px;
  opacity: 0.8;
}

.circular-container {
  position: relative;
  width: 200px;
  height: 200px;
  border: 8px solid #aaa;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-bottom: 20px;
}

.circular-container button {
  color: white;
}

.stop-blind-button {
  border: 1px solid rgba(0, 0, 0, 0.2);
  outline: none;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.rotate-up-blind-button {
  border: 1px solid rgba(0, 0, 0, 0.2);
  outline: none;
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.rotate-down-blind-button {
  border: 1px solid rgba(0, 0, 0, 0.2);
  outline: none;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.flip-y {
  transform: scaleY(-1);
}

.flip-x {
  transform: scaleX(-1);
}

.circular-double-button button:active,
.circular-container button[disabled],
.circular-container button:active,
.pressed {
  filter: brightness(80%);
  box-shadow: inset 0px 5px 10px 5px rgba(0, 0, 0, 0.2);
}

.device-modal-info {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 10px;
  font-size: 16pt;
  font-weight: 300;
  margin-bottom: 20px;
}

.device-disconnected {
  position: absolute;
  left: -5px;
  top: -5px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: $accent-red;
}

.device-disconnected-map {
  position: absolute;
  left: 3px;
  top: 3px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: $accent-red;
}

.device-disconnected-text {
  position: relative;
  left: 0px;
  top: -15px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: $accent-red;
}

.mr-10 {
  margin-right: 20px;
}

.circular-upper-button {
  flex: 1;
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
  outline: none;
  padding: 0;
  border: none;
}

.circular-button {
  flex: 1;
  border-radius: 50%;
  outline: none;
  padding: 0;
  border: none;
}

.circular-downer-button {
  flex: 1;
  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 100px;
  outline: none;
  padding: 0;
  border: none;
}

.translateY-75 {
  position: relative;
  margin-top: (-150px);
}

.translateY-155 {
  position: relative;
  margin-top: (-155px);
}

.translateY75 {
  margin-top: (75px);
}

.translateY50 {
  margin-top: (50px);
}

.dim-control-button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.dim-control-button > button {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  outline: none;
  padding: 0px;
  font-size: 26pt;
  font-weight: 300;
  border: none;
}

.dim-control-button > button:active {
  filter: brightness(80%);
}

.device-button-info {
  display: flex;
  align-items: center;
}

.modal-content > * {
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.rgb-color-selector {
  outline: none;
  border: 2px solid rgba(0, 0, 0, 0.2);
  width: 40px;
  height: 40px;
  margin: 5px;
  display: inline-block;
}

.rgb-selector-container {
  width: 200px;
}

/* Special styling for WebKit/Blink */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background-color: white;
}

/* All the same stuff for Firefox */
input[type="range"]::-moz-range-thumb {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background-color: white;
}

/* All the same stuff for IE */
input[type="range"]::-ms-thumb {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background-color: white;
}

.thermo-device-name {
  font-size: 18pt;
}

.thermo-device-info {
  width: 100%;
}

.thermo-device-temperature {
  display: inline-block;
  width: 50%;
  font-variant-numeric: tabular-nums;
  font-size: 42pt;
}

.thermo-device-sethum {
  width: 50%;
  display: inline-block;
}

.thermo-device-setpoint,
.thermo-device-humidity {
  font-size: 18pt;
  text-align: right;
  line-height: 90%;
}

.thermo-device-occupancy {
  font-size: 16pt;
  margin-top: -15px;
  font-weight: 200;
}

.thermo-device-icons {
  font-size: 18pt;
  width: 50%;
  display: inline-block;
}

.thermo-device-fanspeed {
  font-size: 18pt;
  display: block;
  width: 50%;
  float: right;
  text-align: right;
}

.off-icon {
  opacity: 0.3;
}

.light-icon {
    opacity: 0.5;
}


.thermo-device-offset {
  position: absolute;
  top: -20px;
  right: -25px;
  font-size: 16pt;
}

.sliderHandle {
  z-index: 15;
}

.sliderHandle:after {
  width: 20px !important;
  height: 20px !important;
  border-radius: 50% !important;
  right: -7px !important;
  top: -15px !important;
}

.thermo-device-lower-circle {
  z-index: 90;
  border: 8px solid rgb(204, 204, 204);
  width: 200px;
  height: 100px;
  margin-top: (-100px);
  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 100px;
  border-top: none;
  display: flex;
  flex-direction: column-reverse;
}

.thermo-device-history {
  background-color: transparent;
  border: none;
  outline: none;
  margin-bottom: 5px;
  font-size: 16pt;
  z-index: 90;
}

.thermo-device-fan-control {
  display: flex;
  margin-top: 25px;
  width: 100%;
  font-size: 16pt;
}

.thermo-device-fan-input {
  margin-left: 10px;
  -webkit-appearance: none;
  height: 8px;
  margin-top: 5px;
  border-radius: 4px;
  outline: none;
  width: 100%;
}

.fa-play {
  margin-left: 4px;
}

.close-button {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  margin: 10px;
  border: none;
  outline: none;
  z-index: 101;
}

.clipped-text {
  color: transparent !important;
  background-clip: text !important;
  -webkit-background-clip: text !important;
}
