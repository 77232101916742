@import './variable.scss';

@import 'custom/sidebar';
@import 'custom/menu';
@import 'custom/display';
@import 'custom/settings';
@import 'custom/devices';
@import 'custom/scenario';
@import 'custom/loading';
@import 'custom/levels';
@import 'custom/home';
@import 'custom/mapbackground';
@import 'custom/programs';
@import 'custom/calendar';
@import 'custom/alarms';
@import 'bootstrap';

// Slick
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

// Themes
@import './themes/light.scss';
@import './themes/dark.scss';
@import './themes/colors.scss';

@import './fonts/fira-sans-extra-condensed/fira-sans-extra-condensed.scss';
@import './fonts/fira-sans-condensed/fira-sans-condensed.scss';
@import './fonts/fira-sans/fira-sans.scss';
@import './fonts/barlow-condensed/barlow-condensed.scss';
@import '~pretty-checkbox/src/pretty-checkbox.scss';

@import '~@fullcalendar/daygrid/main.css';
@import '~@fullcalendar/timegrid/main.css';
@import '~@fullcalendar/list/main.css';
@import '~@fullcalendar/bootstrap/main.css';

// Custom Icons
// @import './fontello/css/fontello.css';
// @import './fontello/css/animation.css';
@import './icomoon/style.css';
// @import './glyphter-font/css/Glyphter.css';
#root,
html {
  height: 100%;
}

body {
  color: #000;
  font-family: 'Fira Sans Extra Condensed', sans-serif;
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  -o-user-select: none;
  user-select: none;
  height: 100%;
  //   position: fixed;
  //   min-height: 100vh;
  //   width: 100vw;
}

body.swal2-shown,
body.modal-open {
  height: 100vh !important;
  width: 100vw !important;
  overflow-y: hidden;
}

button:focus,
.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}

label {
  font-weight: 200 !important;
}

button.close {
  text-shadow: none;
}

.fa,
.far,
.fas {
  font-family: 'Font Awesome 5 Free' !important;
}

.modal-body {
  overflow-y: scroll;
}

.dropdown-toggle:after {
  content: none !important;
}

button.bg-primary:hover,
.btn-primary:hover {
  background-color: var(--accent);
}

.modal-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.ripple {
  opacity: 0.2;
}

.no-border {
  border: none;
}

.editable-text-button {
  // font-size: 12pt;
  // border-radius: 50%;
  background-color: red;
}

h4 {
  font-weight: 200;
}
