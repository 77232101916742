// .alarms-table-description {
//
//     display: block;
//     overflow: hidden;
//     white-space: nowrap;
//     text-overflow: ellipsis;
//     max-width: 400px !important;
// }

.ellipsis {
    position: relative;

    @media (max-width: $screen-md) {
        display: none !important;
    }
}

.ellipsis:before {
    content: '&nbsp;';
    visibility: hidden;
}

.ellipsis span {
    position: absolute;
    left: 0;
    right: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
