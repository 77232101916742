/* fira-sans-100 - latin */
@font-face {
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 100;
    src: local('Fira Sans Thin'), local('FiraSans-Thin'),
        url('./fira-sans-v8-latin-100.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./fira-sans-v8-latin-100.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* fira-sans-100italic - latin */
@font-face {
    font-family: 'Fira Sans';
    font-style: italic;
    font-weight: 100;
    src: local('Fira Sans Thin Italic'), local('FiraSans-ThinItalic'),
        url('./fira-sans-v8-latin-100italic.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./fira-sans-v8-latin-100italic.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* fira-sans-200 - latin */
@font-face {
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 200;
    src: local('Fira Sans ExtraLight'), local('FiraSans-ExtraLight'),
        url('./fira-sans-v8-latin-200.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./fira-sans-v8-latin-200.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* fira-sans-200italic - latin */
@font-face {
    font-family: 'Fira Sans';
    font-style: italic;
    font-weight: 200;
    src: local('Fira Sans ExtraLight Italic'),
        local('FiraSans-ExtraLightItalic'),
        url('./fira-sans-v8-latin-200italic.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./fira-sans-v8-latin-200italic.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* fira-sans-300 - latin */
@font-face {
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 300;
    src: local('Fira Sans Light'), local('FiraSans-Light'),
        url('./fira-sans-v8-latin-300.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./fira-sans-v8-latin-300.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* fira-sans-300italic - latin */
@font-face {
    font-family: 'Fira Sans';
    font-style: italic;
    font-weight: 300;
    src: local('Fira Sans Light Italic'), local('FiraSans-LightItalic'),
        url('./fira-sans-v8-latin-300italic.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./fira-sans-v8-latin-300italic.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* fira-sans-regular - latin */
@font-face {
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 400;
    src: local('Fira Sans Regular'), local('FiraSans-Regular'),
        url('./fira-sans-v8-latin-regular.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./fira-sans-v8-latin-regular.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* fira-sans-italic - latin */
@font-face {
    font-family: 'Fira Sans';
    font-style: italic;
    font-weight: 400;
    src: local('Fira Sans Italic'), local('FiraSans-Italic'),
        url('./fira-sans-v8-latin-italic.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./fira-sans-v8-latin-italic.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* fira-sans-500italic - latin */
@font-face {
    font-family: 'Fira Sans';
    font-style: italic;
    font-weight: 500;
    src: local('Fira Sans Medium Italic'), local('FiraSans-MediumItalic'),
        url('./fira-sans-v8-latin-500italic.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./fira-sans-v8-latin-500italic.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* fira-sans-500 - latin */
@font-face {
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 500;
    src: local('Fira Sans Medium'), local('FiraSans-Medium'),
        url('./fira-sans-v8-latin-500.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./fira-sans-v8-latin-500.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* fira-sans-600italic - latin */
@font-face {
    font-family: 'Fira Sans';
    font-style: italic;
    font-weight: 600;
    src: local('Fira Sans SemiBold Italic'), local('FiraSans-SemiBoldItalic'),
        url('./fira-sans-v8-latin-600italic.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./fira-sans-v8-latin-600italic.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* fira-sans-600 - latin */
@font-face {
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 600;
    src: local('Fira Sans SemiBold'), local('FiraSans-SemiBold'),
        url('./fira-sans-v8-latin-600.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./fira-sans-v8-latin-600.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* fira-sans-700 - latin */
@font-face {
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 700;
    src: local('Fira Sans Bold'), local('FiraSans-Bold'),
        url('./fira-sans-v8-latin-700.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./fira-sans-v8-latin-700.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* fira-sans-700italic - latin */
@font-face {
    font-family: 'Fira Sans';
    font-style: italic;
    font-weight: 700;
    src: local('Fira Sans Bold Italic'), local('FiraSans-BoldItalic'),
        url('./fira-sans-v8-latin-700italic.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./fira-sans-v8-latin-700italic.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* fira-sans-800 - latin */
@font-face {
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 800;
    src: local('Fira Sans ExtraBold'), local('FiraSans-ExtraBold'),
        url('./fira-sans-v8-latin-800.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./fira-sans-v8-latin-800.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* fira-sans-800italic - latin */
@font-face {
    font-family: 'Fira Sans';
    font-style: italic;
    font-weight: 800;
    src: local('Fira Sans ExtraBold Italic'), local('FiraSans-ExtraBoldItalic'),
        url('./fira-sans-v8-latin-800italic.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./fira-sans-v8-latin-800italic.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* fira-sans-900 - latin */
@font-face {
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 900;
    src: local('Fira Sans Black'), local('FiraSans-Black'),
        url('./fira-sans-v8-latin-900.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./fira-sans-v8-latin-900.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* fira-sans-900italic - latin */
@font-face {
    font-family: 'Fira Sans';
    font-style: italic;
    font-weight: 900;
    src: local('Fira Sans Black Italic'), local('FiraSans-BlackItalic'),
        url('./fira-sans-v8-latin-900italic.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./fira-sans-v8-latin-900italic.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}